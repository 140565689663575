var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:_vm.infoModal.id,attrs:{"id":_vm.infoModal.id,"size":"lg","ok-title":"Guardar","cancel-title":"Cancelar","centered":"","no-close-on-backdrop":""},on:{"ok":_vm.onSubmit},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('span',{staticClass:"tituloModalInmueble"},[_vm._v("Seleccionar cliente")])]},proxy:true}])},[_c('b-form',{attrs:{"id":"FormUser"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(data){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Género"}},[_c('b-radio-group',{attrs:{"name":"genero"},model:{value:(_vm.form.genero.id),callback:function ($$v) {_vm.$set(_vm.form.genero, "id", $$v)},expression:"form.genero.id"}},[_vm._l((_vm.storeConfig.gender),function(item){return [_c('b-form-radio',{key:item.id,attrs:{"state":_vm.errorState(errors),"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])]})],2)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Nombre completo"}},[_c('b-form-input',{attrs:{"state":_vm.errorState(errors),"trim":""},model:{value:(_vm.form.primer_nombre),callback:function ($$v) {_vm.$set(_vm.form, "primer_nombre", $$v)},expression:"form.primer_nombre"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Tipo de Documento","state":!(errors.length > 0)}},[_c('v-select',{attrs:{"input-id":"tipo_documento","label":"nombre","options":_vm.storeConfig.document_type,"reduce":function (type) { return type.id; },"clearable":false},model:{value:(_vm.form.tipo_identificacion),callback:function ($$v) {_vm.$set(_vm.form, "tipo_identificacion", $$v)},expression:"form.tipo_identificacion"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"N° de Identificación"}},[_c('b-form-input',{attrs:{"state":_vm.errorState(errors),"trim":""},model:{value:(_vm.form.cedula_persona),callback:function ($$v) {_vm.$set(_vm.form, "cedula_persona", $$v)},expression:"form.cedula_persona"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Role","state":!(errors.length > 0)}},[_c('v-select',{attrs:{"label":"nombre","options":_vm.roles,"reduce":function (type) { return type.id; },"clearable":false},model:{value:(_vm.form.roles),callback:function ($$v) {_vm.$set(_vm.form, "roles", $$v)},expression:"form.roles"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Plan","state":!(errors.length > 0)}},[_c('v-select',{attrs:{"label":"code","options":_vm.optionsPlans,"reduce":function (type) { return type.id; },"clearable":false},model:{value:(_vm.form.payment_plans),callback:function ($$v) {_vm.$set(_vm.form, "payment_plans", $$v)},expression:"form.payment_plans"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"E-mail"}},[_c('b-form-input',{attrs:{"state":_vm.errorState(errors),"trim":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Celular"}},[_c('vue-phone-number-input',{ref:"inputCelular",attrs:{"error":errors.length > 0,"show-code-on-list":"","default-country-code":"CO","preferred-countries":['CO'],"translations":{
                                    countrySelectorLabel: 'Código país',
                                    countrySelectorError: 'Elije un código',
                                    phoneNumberLabel: 'Celular',
                                    example: 'Ejemplo :',
                                }},on:{"update":function (event) {
                                    _vm.form.celular_movil = event.nationalNumber;
                                    _vm.wame.number = event.e164;
                                    if (_vm.wame.state) { _vm.phone.celular_whatsapp = event.e164; }
                                    _vm.exampleLabel(event)
                                }},model:{value:(_vm.form.celular_movil),callback:function ($$v) {_vm.$set(_vm.form, "celular_movil", $$v)},expression:"form.celular_movil"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"WhatsApp"}},[_c('vue-phone-number-input',{ref:"whatsapp",attrs:{"error":errors.length > 0,"show-code-on-list":"","default-country-code":"CO","preferred-countries":['CO'],"translations":{
                                    countrySelectorLabel: 'Código país',
                                    countrySelectorError: 'Elije un código',
                                    phoneNumberLabel: 'WhatsApp',
                                    example: 'Ejemplo :',
                                },"disabled":_vm.wame.state},on:{"update":function($event){_vm.form.celular_whatsapp = $event.e164}},model:{value:(_vm.form.celular_whatsapp),callback:function ($$v) {_vm.$set(_vm.form, "celular_whatsapp", $$v)},expression:"form.celular_whatsapp"}})],1)]}}],null,true)}),_c('b-form-checkbox',{staticClass:"mb-0 mt-1",attrs:{"id":"wame","name":"wame"},model:{value:(_vm.wame.state),callback:function ($$v) {_vm.$set(_vm.wame, "state", $$v)},expression:"wame.state"}},[_vm._v(" Usar el mismo numero de Celular ")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Dirección"}},[_c('b-form-input',{attrs:{"state":_vm.errorState(errors),"trim":""},model:{value:(_vm.form.direccion_persona),callback:function ($$v) {_vm.$set(_vm.form, "direccion_persona", $$v)},expression:"form.direccion_persona"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"País","state":!(errors.length > 0)}},[_c('v-select',{attrs:{"label":"name","options":_vm.storeConfig.country,"reduce":function (type) { return type.id; },"clearable":false},on:{"input":function($event){return _vm.resetUbicacion('id_pais')}},model:{value:(_vm.form.id_pais),callback:function ($$v) {_vm.$set(_vm.form, "id_pais", $$v)},expression:"form.id_pais"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Departamento","state":!(errors.length > 0)}},[_c('v-select',{attrs:{"label":"name","options":_vm.ubicaciones['id_estado'].options,"reduce":function (type) { return type.id; },"disabled":_vm.ubicaciones['id_estado'].options.length == 0,"clearable":false},on:{"input":function($event){return _vm.resetUbicacion('id_estado')}},model:{value:(_vm.form.id_estado),callback:function ($$v) {_vm.$set(_vm.form, "id_estado", $$v)},expression:"form.id_estado"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Ciudad","state":!(errors.length > 0)}},[_c('v-select',{attrs:{"label":"name","options":_vm.ubicaciones['id_ciudad'].options,"reduce":function (type) { return type.id; },"clearable":false,"disabled":_vm.ubicaciones['id_ciudad'].options.length == 0},on:{"input":function($event){return _vm.resetUbicacion('id_ciudad')}},model:{value:(_vm.form.id_ciudad),callback:function ($$v) {_vm.$set(_vm.form, "id_ciudad", $$v)},expression:"form.id_ciudad"}})],1)]}}],null,true)})],1)],1),(data.failed)?_c('div',{staticClass:"list-error"},[_c('div',{staticClass:"alert alert-danger p-1 mb-0 flex-grow-1 text-center",style:({ fontSize: '14px' })},[_c('p',[_vm._v("Uno o más campos obligatorios se encuentran vacios, Valida e intenta nuevamente. ")])])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }